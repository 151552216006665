import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import '../css/greater-love-map.scss';

const loader = new Loader({
  apiKey: window.__maps_config.api_key,
  version: 'weekly',
  libraries: [
    //
  ],
});

const createMap = async (el, index) => {
  const { centerMode, ...mapConfig } = JSON.parse(el.dataset.wdeMaps ?? '{}');

  mapConfig.markers = Array.from(el.querySelectorAll('[data-wde-maps-marker]') ?? []).map((m) => ({
    ...JSON.parse(m.dataset.wdeMapsMarker ?? '{}'),
    infoWindowContent: m.innerHTML,
  }));

  const [CoreLibrary, MapsLibrary, MarkersLibrary] = await Promise.all([loader.importLibrary('core'), loader.importLibrary('maps'), loader.importLibrary('marker')]);
  const { clustering = false, clusteringIcon = undefined, highlightSwitzerland = false } = mapConfig;

  if (!mapConfig.mapId) {
    mapConfig.mapId = `WORDPRESS_MAPS_${index ?? 0}`;
  }

  if (!mapConfig.center) {
    mapConfig.center = mapConfig.markers.at(0).position;
  }

  const map = new MapsLibrary.Map(el, {
    ...mapConfig,
    mapTypeControl: false,
    streetViewControl: false,
  });

  if (centerMode === 'all_markers' || mapConfig.center) {
    const bounds = new CoreLibrary.LatLngBounds();

    if (centerMode === 'all_markers') {
      mapConfig.markers.forEach((marker) => bounds.extend(marker.position));

      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    } else if (centerMode === 'switzerland') {
      bounds.extend({ lng: 6.02260949059, lat: 45.7769477403 });
      bounds.extend({ lng: 10.4427014502, lat: 47.8308275417 });

      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    } else if (mapConfig.center) {
      bounds.extend(mapConfig.center);

      map.setCenter(bounds.getCenter());
      map.setZoom(mapConfig.zoom);
    }
  }

  if (map.mapId && highlightSwitzerland) {
    const featureLayer = map.getFeatureLayer('COUNTRY');

    const featureStyleOptions = {
      strokeColor: '#004B95',
      strokeOpacity: 1.0,
      strokeWeight: 3.0,
      fillColor: '#004B95',
      fillOpacity: 0.3,
    };

    // Apply the style to a single boundary.
    featureLayer.style = (options) => {
      if (options.feature.placeId == 'ChIJYW1Zb-9kjEcRFXvLDxG1Vlw') {
        // Above Place ID is Switzerland
        return featureStyleOptions;
      }
    };
  }

  const infoWindow = new MapsLibrary.InfoWindow();
  const markers = [];

  mapConfig.markers.forEach((markerConfig) => {
    const { icon = undefined, infoWindowContent = undefined, ...config } = markerConfig;

    if (icon) {
      const pin = document.createElement('img');
      pin.src = icon;

      config.content = pin;
    }

    const marker = new MarkersLibrary.AdvancedMarkerElement({
      ...config,
      map,
    });

    if (infoWindowContent) {
      marker.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(infoWindowContent);
        infoWindow.open(map, marker);
      });
    }

    markers.push(marker);
  });

  if (clustering) {
    const renderCluster = {
      render({ count, position }) {
        const clusterConfig = {
          label: { text: String(count), color: 'white' },
          position,
        };

        if (clusteringIcon) {
          clusterConfig.icon = {
            url: clusteringIcon,
            width: 50,
            height: 50,
            textColor: '#fff',
            textLineHeight: 50,
          };
        }

        return new MarkersLibrary.Marker(clusterConfig);
      },
    };

    const clusterer = new MarkerClusterer({
      map,
      markers,
      renderer: renderCluster,
    });

    clusterer.addMarkers(markers);
  }
};

window.onload = (event) => {
  document.querySelectorAll('[data-wde-maps]').forEach((el, index) => createMap(el, index));
};
